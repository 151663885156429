<template>
  <div>
    <div class="top" style="margin: 20px 0">
      <el-button @click="loanList" type="primary">刷新</el-button>
      <el-button
        type="primary"
        style="margin-right: 15px"
        @click="allPart('duo')"
        >批量分单</el-button
      >
      <el-date-picker
        style="margin-right: 20px"
        v-model="time"
        @change="timeUpdate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-select
        v-model="queryList.sex"
        placeholder="所有性别"
        @change="changeCondition"
        clearable
      >
        <el-option label="男" value="男"> </el-option>
        <el-option label="女" value="女"> </el-option>
      </el-select>
      <el-select
        v-model="queryList.systemUserId"
        placeholder="所有员工"
        @change="changeCondition"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.userName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        type="text"
        style="width: 215px"
        placeholder="请输入手机号"
        v-model="queryList.phone"
        clearable
        @change="
          () => {
            if (!queryList.phone) {
              loanList();
            }
          }
        "
      ></el-input>
      <el-button @click="loanList" type="primary" style="margin-right: 20px"
        >搜索</el-button
      >
      <!-- <el-radio-group v-model="queryList.status" @change="loanList">
        <el-radio :label="0">正常用户</el-radio>
        <el-radio :label="1">放弃用户</el-radio>
      </el-radio-group> -->
    </div>
    <div class="top-select">
      <div :class="{ selected: queryList.orderBy === 0 }" @click="selectSort(0)">未分配排序</div>
      <div :class="{ selected: queryList.orderBy === 1 }" @click="selectSort(1)">申请时间排序</div>
    </div>
    <el-dialog title="跟单情况" :visible.sync="dialogTableVisible">
      <Documentary :idc="idc" v-if="dialogTableVisible"></Documentary>
    </el-dialog>
    <el-dialog :visible.sync="dialogTableVisible2" title="">
      <div class="content" v-if="dialogTableVisible2">
        <div style="font-weight: 700; color: #000">
          <p>姓名：{{ detailsValue.userName }}</p>
          <p>所在城市：{{ detailsValue.city }}</p>
          <p>申请金额：{{ detailsValue.loanMoney }}</p>
          <p>性别：{{ detailsValue.sex }}</p>
          <p>年龄：{{ detailsValue.age }}</p>
          <p>芝麻分：{{ detailsValue.sesameSeed }}</p>
          <p>身份：{{ detailsValue.job }}</p>
          <p>社保：{{ detailsValue.socialSecurity }}</p>
          <p>公积金：{{ detailsValue.accumulationFund }}</p>
          <p>保险：{{ detailsValue.insurance }}</p>
          <p>车产：{{ detailsValue.carStatus }}</p>
          <p>房产：{{ detailsValue.houseStatus }}</p>
          <p>本科：{{ detailsValue.study }}</p>
          <p>逾期：{{ detailsValue.overdue }}</p>
          <p>信用卡：{{ detailsValue.creditCard }}</p>
          <p>微粒贷：{{ detailsValue.smallLoan }}</p>
        </div>
        <div style="font-weight: 700; color: #000; text-align: center">
          <p>推送时间：{{ detailsValue.updateTime }}</p>
        </div>
        <p style="width: 100%; text-align: center">
          <el-button @click="dialogTableVisible2 = false" type="primary"
            >确认</el-button
          >
        </p>
      </div>
    </el-dialog>

    <el-dialog title="分单" :visible.sync="dialogTableVisible1">
      <span style="margin-right: 10px">选择信贷员</span>
      <el-select
        v-model="instIds"
        placeholder="请选择"
        clearable
        style="width: 300px"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.userName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <p style="margin-top: 160px; text-align: center">
        <el-button style="width: 150px" @click="partq">确认</el-button>
      </p>
    </el-dialog>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="userName" label="姓名"> </el-table-column>
      <el-table-column prop="phone" label="手机号码">
        <template slot-scope="scope">
          <el-button
            v-show="!scope.row.phone"
            @click="showUserPhone(scope.row.id)"
            type="primary"
            >查看</el-button
          >
          <div>{{ scope.row.phone }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="city" label="城市"> </el-table-column>
      <el-table-column prop="pushTime" label="申请时间"> </el-table-column>
      <el-table-column prop="name" label="分配情况">
        <template slot-scope="scope">
          <!-- <span
            style="
              border-radius: 4px;
              border: 1px solid rgba(187, 187, 187, 100);
              padding: 2px 5px;
              margin-right: 5px;
            "
            v-for="(item, index) in scope.row.institutionName.split(',')"
            :key="index"
            >{{ item }}</span
          > -->{{ scope.row.institutionName }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="跟单情况">
        <template slot-scope="scope"
          ><el-button size="small" @click="look(scope.row.id)"
            >查看</el-button
          ></template
        >
      </el-table-column>
      <el-table-column prop="name" label="设置" width="230">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            @click="allPart('shao', scope.row)"
            >分单</el-button
          >
          <!-- <el-button
            size="small"
            v-if="scope.row.delFalg == 0"
            type="danger"
            @click="updateStatus(scope.row)"
            >放弃</el-button
          > -->
          <el-button
            size="small"
            v-if="scope.row.delFalg == 1"
            type="success"
            @click="updateStatus(scope.row)"
            >激活</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="queryList.pageIdx"
      :limit.sync="queryList.pageSize"
      @pagination="pagination"
    />
  </div>
</template>

<script>
function jialing(num) {
  if (num >= 10) {
    return num;
  } else {
    return "0" + num;
  }
}
var day1 = new Date();
day1.setTime(day1.getTime());
var s1 =
  day1.getFullYear() +
  "-" +
  jialing(day1.getMonth() + 1) +
  "-" +
  jialing(day1.getDate());
var day3 = new Date();
day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
var s3 =
  day3.getFullYear() +
  "-" +
  jialing(day3.getMonth() + 1) +
  "-" +
  jialing(day3.getDate());
import {
  list_user,
  find_all,
  del_flag,
  part_user,
  find_by,
  find_all_id,
  part_one_user,
  find_phone
} from "../../api/OrderManagement";
import Pagination from "@/components/Pagination";
import Documentary from "./documentary";
export default {
  components: {
    Pagination,
    Documentary,
  },
  data() {
    return {
      institutionId: [],
      time: [s1, s3],
      queryList: {
        startTime: "",
        endTime: "",
        status: 0,
        pageIdx: 1,
        pageSize: 10,
        sex: "",
        systemUserId: "",
        phone: '',
        orderBy: 0
      },
      options: [],
      tableData: [],
      total: 0,
      dialogTableVisible: false,
      idc: 0,
      dialogTableVisible1: false,
      instIds: "",
      userIds: "",
      dialogTableVisible2: false,
      detailsValue: {},
      userIdsCopy: [],
      allOrOne: "",
    };
  },
  mounted() {
    this.queryList.startTime = s1;
    this.queryList.endTime = s3;
    this.loanList();
    find_all({
      status: 1,
    }).then((res) => {
      console.log(res, '所有员工')
      this.options = res.data;
    });
  },
  methods: {
    selectSort(type) {
      this.queryList.orderBy = type;
      this.loanList();
    },
    showUserPhone(id) {
      find_phone(id).then((res) => {
        if (res.code === 200) {
          this.tableData.forEach((item) => {
            if (item.phone) {
              delete item.phone;
              return false;
            }
          })
          this.tableData.forEach((item) => {
            if (item.id === id) {
              item.phone = res.data;
              this.tableData = JSON.parse(JSON.stringify(this.tableData))
              return false;
            }
          })
        }
      })
    },
    loanList() {
      list_user(this.queryList).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.tableData.forEach((item) => {
          if (item.institutionName != "") {
            item.institutionName = item.institutionName
          }
        });
      });
    },
    timeUpdate(val) {
      if (val != null) {
        this.queryList.startTime = val[0];
        this.queryList.endTime = val[1];
      } else {
        this.queryList.startTime = "";
        this.queryList.endTime = "";
      }
      this.queryList.pageIdx = 1;
      this.loanList();
    },
    handleSelectionChange(val) {
      this.userIdsCopy = [];
      this.institutionIdCopy = [];
      val.forEach((item) => {
        this.userIdsCopy.push(item.id);
      });
    },
    look(id) {
      this.idc = id;
      this.dialogTableVisible = true;
    },
    updateStatus(data) {
      var delFalg = data.delFalg == 0 ? 1 : 0;
      var title = data.delFalg == 0 ? "放弃" : "激活";
      del_flag({
        delFalg: delFalg,
        userId: data.id,
      }).then((res) => {
        if (res.code == 200) {
          this.loanList();
          this.$message({
            message: `${title}成功`,
            type: "success",
          });
        }
      });
    },
    allPart(title, data) {
      this.allOrOne = title;
      if (title == "shao") {
        this.userIds = data.id;
        this.institutionId = []
        this.institutionId.push(data.institutionId)
        var ids = [];
        find_all_id({
          userId: data.id,
        }).then((res) => {
          res.data.forEach((item) => {
            if (item.status) {
              ids.push(item.userName);
            }
          });
          this.instIds = ids;
        });
        this.dialogTableVisible1 = true;
      } else {
        this.userIds = this.userIdsCopy;
        this.userIds = this.userIdsCopy;
        if (this.userIds.length < 1) {
          this.$message.error("请选择");
        } else {
          this.instIds = [];
          this.userIds = this.userIds
          this.dialogTableVisible1 = true;
        }
      }
    },
    partq() {
      this.instIds = this.instIds
      if (this.allOrOne == "shao") {
        part_one_user({
          userId: this.userIds,
          instIds: this.instIds,
          institutionId: this.institutionId.join("")
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: `分单成功`,
              type: "success",
            });
            this.dialogTableVisible1 = false;
            this.loanList();
            this.instIds = [];
          } else {
            // this.$message.error(res.msg);
          }
        });
      } else {
        this.institutionId = []
        this.tableData.forEach(item => {
          if(!this.userIds.includes(item)) {
            this.institutionId.push(item.institutionId)
          }
        })
        part_user({
          userIds: this.userIds.join(","),
          instIds: this.instIds,
          institutionId: this.institutionId.join(",")
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: `分单成功`,
              type: "success",
            });
            this.dialogTableVisible1 = false;
            this.loanList();
            this.instIds = [];
          }
        });
      }
    },
    details(id) {
      find_by(id).then((res) => {
        if (res.code == 200) {
          this.detailsValue = res.data;
          this.dialogTableVisible2 = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.queryList.pageIdx = params.page;
      } else {
        this.queryList.pageIdx = 1;
        this.queryList.pageSize = params.limit;
      }
      this.loanList();
    },
    //修改上方条件
    changeCondition() {
      this.queryList.pageIdx = 1;
      this.loanList();
    }
  },
};
</script>

<style scoped>
.top-select {
  display: flex;
  margin-bottom: 10px;
}

.top-select div {
  margin-right: 5px;
  padding: 12px 20px;
  border: 1px solid rgb(220, 223, 230);
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.top-select .selected {
  background: rgb(64, 158, 255);
  color: #fff;
}

::v-deep .el-date-editor .el-range-separator {
  width: 9%;
}
::v-deep .el-date-editor--daterange.el-input__inner {
  width: 270px !important;
}
::v-deep .el-select {
  width: 150px;
  margin-right: 10px;
}
::v-deep .el-radio {
  margin-right: 10px;
}
::v-deep thead th {
  text-align: center;
  color: #000;
  background: rgb(250, 250, 250);
}

::v-deep tbody .cell {
  text-align: center;
}
.content {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.content div {
  float: left;
  margin-left: 100px;
}

.content div p {
  margin-bottom: 10px;
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-pagination {
  text-align: center;
}
</style>
